<template>
    <div class="card">
        <div class="bg-blue-light p-2">
            <TitleButton
                :showBtn="false"
                btnTitle="Add New"
                title="Cash & Bank Register"
            />
            <div class="row match-height">
                <div class="col-12 col-lg-8 col-xl-9 col-xxl-7 mt-2">
                    <DateQuerySetter
                        @onClickGo="getDateWiseCashAndBank"
                    />
                </div>
            </div>
        </div>

        <div class="mt-2 px-2">
            <CashAndBankTable
                :tableData="cashBank"
            />
        </div>
        <GlobalLoader />
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useRoute} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import CashAndBankTable from '@/components/molecule/company/report/CashAndBankTable.vue'
import useReport from '@/services/modules/dashboard/report.js'
const {cashBank, fetchCashBank} = useReport()
const route = useRoute()
const offset = ref(1000)
onMounted(() => {
    fetchCashBank(getQuery())
})
function getDateWiseCashAndBank () {
    fetchCashBank(getQuery())
}
function getQuery () {
    let queryStr = '?company_id=' + route.params.companyId
    queryStr += '&offset=' + offset.value
    if(!route.query.start || !route.query.end) return queryStr
    return queryStr+`&cash_and_banks_date={
        "start": "${route.query.start}",
        "end": "${route.query.end}"
        }`
}

</script>
